import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import type {CardComponent, LinkComponent, TagComponent} from '@/types';
import {ColorSchemeEnum} from '@/enums';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {getButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Image from '@/components/shared/Image/Image';

interface CardProps extends CardComponent {
  /* this is available when passed down from Cards */
  button?: LinkComponent;
  headingClasses?: string;
  componentName?: string;
  /* this primitive is inclusive of Card-derivative types */
  tags?: TagComponent[];
}

/**
 * @deprecated
 */
export default function Card({
  button,
  className,
  headingClasses,
  colorScheme,
  contentHtml,
  componentName = 'card',
  kicker,
  heading,
  icon,
  image,
  link,
  tags,
}: CardProps) {
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  return (
    <div
      key={heading}
      data-component-name={componentName}
      className={twMerge(
        'border-shade-30 h-full rounded-[18px] border-[1px] default:p-6',
        sectionSchemeClasses[currentScheme],
        className,
      )}
    >
      <div className="flex flex-col h-full">
        {icon?.src && (
          <Image
            alt={icon.alt}
            className="h-16 w-16 rounded-md object-contain"
            loading="lazy"
            src={icon.src}
          />
        )}
        {image?.srcSet && (
          <Image
            alt={image.alt}
            className="w-full rounded-md"
            loading={image.loading || 'lazy'}
            sizes={image.sizes}
            srcSet={image.srcSet}
          />
        )}
        {kicker && (
          <>
            <h3 className="font-bold text-t8 mt-1 uppercase">{kicker}</h3>
            <Spacer size="xs" />
          </>
        )}
        {heading && (
          <>
            <Spacer size="lg" />
            <h3
              className={twMerge(
                'font-bold text-t5 default:hyphens-auto',
                headingClasses,
              )}
            >
              {heading}
            </h3>
          </>
        )}
        {contentHtml && (
          <>
            <Spacer size="sm" />
            <p
              className="text-body-base"
              dangerouslySetInnerHTML={{__html: contentHtml}}
            />
            <Spacer size="md" />
          </>
        )}
        {tags && (
          <div className="flex flex-wrap items-start gap-2">
            {tags.map((tag) => (
              <span
                key={tag.text}
                data-component-name={`${tag.text}-tag`}
                className="inline rounded-full bg-[#d9d9d9] px-3 py-1 text-xs font-medium uppercase leading-tight text-gray-700"
              >
                {tag.text}
              </span>
            ))}
            <Spacer size="md" />
          </div>
        )}
        {button?.url && (
          <Button
            type="link"
            href={button.url}
            theme={getButtonTheme(currentScheme)}
            className={cn({'mr-4': link})}
            componentName={button.name || ''}
          >
            {button.text}
          </Button>
        )}
        {link?.url && (
          <Link
            componentName={link.name || ''}
            href={link.url}
            theme={getButtonTheme(currentScheme)}
            className="mt-auto"
            target={link.target}
          >
            {link?.text}
          </Link>
        )}
      </div>
    </div>
  );
}
